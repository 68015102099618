import router from '@/router'
import jwt from '@/auth/jwt/sclUseJwt'
import { generalMethods } from '@/mixins/generic-functions'

function CreateOperativeDetails(cscscard, workpermit, availability, tradename, vatreg, vatno, sclinv, scltax, sageId, clientId, feeOverride, notes) {
  this.cscscard = cscscard
  this.workpermit = workpermit
  this.availability = availability
  this.tradename = tradename
  this.vatreg = vatreg
  this.vatno = vatno
  this.sclinv = sclinv
  this.scltax = scltax
  this.sage_id = sageId
  this.client_ent_id = clientId
  this.feeoverride = feeOverride
  this.notes = notes
}

function CreateBankDetails(accno, accname, sortcode, bacsref) {
  this.bnkaccno = accno
  this.bnkaccname = accname
  this.bnksortcode = sortcode
  this.bacsref = bacsref
}

function CreateCISDetails(cisreg, utr, taxrate) {
  this.cisreg = cisreg
  this.utr = utr
  this.taxrate = taxrate
}

function CreateSageSyncDetails(sageId) {
  this.sync_id = sageId
}

function CreateGDPRDetails(auth) {
  this.auth = auth
}

function CreateTradeDetails(tradeId) {
  this.trade_id_id = tradeId
}

function CreateVendorDetails() {
}

function CreateEntityClients(feeRate) {
  this.feerate = feeRate
}

export default {
  namespaced: true,
  applicationValidated: null,
  state: {
    applicantSearchCriteria: {
      selectedStatus: 'ALL',
      selectedTrade: 'ALL',
      selectedLastWorked: '',
      Name: '',
      limitToApplicants: true,
    },

    currentApplicationDetails: {
      ent_id: '-1',
      title: '',
      firstname: '',
      lastname: '',
      dob: 'new',
      ninumber: '',
      nationality: '',
      address: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      county: '',
      country: '',
      email: '',
      mobphone: '',
      homephone: '',
      name: '',
      updated: '',
      dayRate: '',
      comp_reg_no: '',
      comptype: 'soletrader',
      trade: '',
      trade_id: -1,
      trade_rate: '0',
      bankinfo_auth: false,
      terms_auth: false,
      ent_type: 'APPLICANT',
      sage_exp_enable: false,
      operativedetails: [new CreateOperativeDetails(false, '', 'new', '', false, '', false, false, '', -1, 0, '')],
      cisdetails: [new CreateCISDetails(null, '', '')],
      tradedetails: [new CreateTradeDetails(-1)],
      vendordetails: [new CreateVendorDetails('')],
    },
    applicantsForCurrentClient: [],
  },
  getters: {
    getApplicationValidation(state) {
      return state.applicationValidated
    },

    getApplicantsForCurrentClient(state) {
      return state.applicantsForCurrentClient
    },

    getCurrentApplication(state) {
      return state.currentApplicationDetails
    },

    getApplicantSearchCriteria(state) {
      return state.applicantSearchCriteria
    },

    getApplicantSearchCriteriaAsParams(state) {
      return generalMethods.getSearchCriteriaAsParams(state, true)
    },
  },
  mutations: {
    getApplicantsForCurrentClient(state, clientOps) {
      state.applicantsForCurrentClient = clientOps
    },

    getCurrentApplication(state, opDetails) {
      state.currentApplicationDetails = opDetails
    },

    resetApplicationValidation(state) {
      state.applicationValidated = null
    },

    startApplicationValidation(state) {
      state.applicationValidated = true
    },

    failApplicationValidation(state) {
      state.applicationValidated = false
    },

    resetApplicantsForCurrentClient(state) {
      state.applicantsForCurrentClient = []
    },

    resetCurrentApplication(state) {
      state.currentApplicationDetails.ent_id = '-1'
      state.currentApplicationDetails.title = ''
      state.currentApplicationDetails.firstname = ''
      state.currentApplicationDetails.lastname = ''
      state.currentApplicationDetails.dob = 'new'
      state.currentApplicationDetails.ninumber = ''
      state.currentApplicationDetails.nationality = ''
      state.currentApplicationDetails.address = ''
      state.currentApplicationDetails.addressLine1 = ''
      state.currentApplicationDetails.addressLine2 = ''
      state.currentApplicationDetails.city = ''
      state.currentApplicationDetails.postcode = ''
      state.currentApplicationDetails.county = ''
      state.currentApplicationDetails.country = ''
      state.currentApplicationDetails.email = ''
      state.currentApplicationDetails.mobphone = ''
      state.currentApplicationDetails.homephone = ''
      state.currentApplicationDetails.name = ''
      state.currentApplicationDetails.updated = ''
      state.currentApplicationDetails.dayRate = ''
      state.currentApplicationDetails.comp_reg_no = ''
      state.currentApplicationDetails.comptype = 'soletrader'
      state.currentApplicationDetails.trade = ''
      state.currentApplicationDetails.trade_id = -1
      state.currentApplicationDetails.trade_rate = '0'
      state.currentApplicationDetails.bankinfo_auth = false
      state.currentApplicationDetails.terms_auth = false
      state.currentApplicationDetails.ent_type = 'APPLICANT'
      state.currentApplicationDetails.sage_exp_enable = false
      state.currentApplicationDetails.operativedetails = []
      state.currentApplicationDetails.operativedetails.push(new CreateOperativeDetails(false, '', 'new', '', false, '', false, false, '', -1, 0, ''))
      state.currentApplicationDetails.cisdetails = []
      state.currentApplicationDetails.cisdetails.push(new CreateCISDetails(null, '', '0'))
      state.currentApplicationDetails.tradedetails = []
      state.currentApplicationDetails.tradedetails.push(new CreateTradeDetails(-1))
      state.currentApplicationDetails.vendordetails = []
      state.currentApplicationDetails.vendordetails.push(new CreateVendorDetails(''))
    },

    setApplicationTitle(state, title) {
      state.currentApplicationDetails.title = title
    },
    setApplicationFirstname(state, firstname) {
      state.currentApplicationDetails.firstname = firstname
    },
    setApplicationLastname(state, lastname) {
      state.currentApplicationDetails.lastname = lastname
    },
    setApplicationDateOfBirth(state, dob) {
      state.currentApplicationDetails.dob = dob
    },
    setApplicationNINumber(state, ninumber) {
      state.currentApplicationDetails.ninumber = ninumber
    },
    setApplicationNationality(state, nationality) {
      state.currentApplicationDetails.nationality = nationality
    },
    setApplicationWorkPermit(state, wp) {
      state.currentApplicationDetails.operativedetails[0].workpermit = wp
    },
    setApplicationEmail(state, email) {
      state.currentApplicationDetails.email = email
    },
    setApplicationMobilePhone(state, mobphone) {
      state.currentApplicationDetails.mobphone = mobphone
    },
    setApplicationHomePhone(state, homephone) {
      state.currentApplicationDetails.homephone = homephone
    },

    setApplicationAddressLine1(state, address) {
      state.currentApplicationDetails.addressLine1 = address
      generalMethods.updateAddress(state.currentApplicationDetails, address, null, null)
    },
    setApplicationAddressLine2(state, address) {
      state.currentApplicationDetails.addressLine2 = address
      generalMethods.updateAddress(state.currentApplicationDetails, null, address, null)
    },
    setApplicationCity(state, city) {
      state.currentApplicationDetails.city = city
      generalMethods.updateAddress(state.currentApplicationDetails, null, null, city)
    },
    setApplicationPostcode(state, postcode) {
      state.currentApplicationDetails.postcode = postcode
    },

    setApplicationTradeName(state, name) {
      state.currentApplicationDetails.operativedetails[0].tradename = name
    },
    setApplicationTradeId(state, tradeId) {
      state.currentApplicationDetails.trade_id = tradeId
    },
    setApplicationAvailability(state, availabilityDate) {
      state.currentApplicationDetails.operativedetails[0].availability = availabilityDate
    },
    setApplicationVatNo(state, vat) {
      state.currentApplicationDetails.operativedetails[0].vatno = vat
    },
    setApplicationCompanyRegNo(state, regNo) {
      state.currentApplicationDetails.comp_reg_no = regNo
    },
    setApplicationUtr(state, utr) {
      state.currentApplicationDetails.cisdetails[0].utr = utr
    },
    setApplicationCompanyType(state, companyType) {
      state.currentApplicationDetails.comptype = companyType
    },
    setApplicationVatReg(state, vatReg) {
      state.currentApplicationDetails.operativedetails[0].vatreg = vatReg
    },
    setApplicationCSCS(state, cscs) {
      state.currentApplicationDetails.operativedetails[0].cscscard = cscs
    },
    setApplicationCisReg(state, reg) {
      state.currentApplicationDetails.cisdetails[0].cisreg = reg
    },
    setApplicationNotes(state, notes) {
      state.currentApplicationDetails.operativedetails[0].notes = notes
    },
    setApplicationBankInfoAuth(state, auth) {
      state.currentApplicationDetails.bankinfo_auth = auth
    },
    setApplicationTermsAuth(state, auth) {
      state.currentApplicationDetails.terms_auth = auth
    },

    initApplicantSearchCriteria(state) {
      state.applicantSearchCriteria.selectedStatus = 'ALL'
      state.applicantSearchCriteria.selectedTrade = 'ALL'
      state.applicantSearchCriteria.selectedLastWorked = ''
      state.applicantSearchCriteria.limitToApplicants = true
    },

    addBankDetails(state) {
      state.currentApplicationDetails.bankdetails = []
      state.currentApplicationDetails.bankdetails.push(new CreateBankDetails('', '', '', ''))
    },
    addGDPRDetails(state) {
      state.currentApplicationDetails.gdprdetails = []
      state.currentApplicationDetails.gdprdetails.push(new CreateGDPRDetails(false))
    },
    addSyncDetails(state) {
      state.currentApplicationDetails.entitysync = []
      state.currentApplicationDetails.entitysync.push(new CreateSageSyncDetails(''))
    },
    addClientDetails(state) {
      state.currentApplicationDetails.entityclients = []
      state.currentApplicationDetails.entityclients.push(new CreateEntityClients(0))
    },
  },
  actions: {
    resetApplicantsForCurrentClient(vuexContext, context) {
      vuexContext.commit('resetApplicantsForCurrentClient', context)
    },
    resetCurrentApplication(vuexContext, context) {
      vuexContext.commit('resetCurrentApplication', context)
    },

    getApplicantsList(vuexContext, params) {
      vuexContext.commit('resetApplicantsForCurrentClient')
      vuexContext.commit('resetCurrentApplication')
      jwt.axiosIns.get(`/applicants/${params}`)
        .then(response => {
          const data = JSON.parse(JSON.stringify(response.data))
          vuexContext.commit('getApplicantsForCurrentClient', data)
        })
        .catch(e => {
          console.error(e)
        })
    },

    getCurrentApplication(vuexContext, context) {
      vuexContext.commit('resetCurrentApplication', context)

      jwt.axiosIns.get(`/applicant/${context.operativeid}/`)
        .then(response => {
          vuexContext.commit('getCurrentApplication', response.data)
          const [addressLine1, addressLine2, city] = response.data.address.toString().split(/\n/)
          vuexContext.commit('setApplicationAddressLine1', addressLine1)
          vuexContext.commit('setApplicationAddressLine2', addressLine2)
          vuexContext.commit('setApplicationCity', city)
        })
        .catch(e => {
          console.error(e)
        })
    },

    rejectApplicant(vuexContext, context) {
      const data = JSON.stringify(vuexContext.state.currentApplicationDetails)
      jwt.axiosIns.put(`/rejectapplicant/${context.operativeid}/`, data)
        .then(response => {
          if (response.data.message !== undefined) {
            generalMethods.failedMessage(response.data.message)
          } else {
            generalMethods.successMessage('', `Applicant '${vuexContext.state.currentApplicationDetails.firstname} ${vuexContext.state.currentApplicationDetails.lastname}' has been rejected.`)
            router.go(-1) // Return to previous page
          }
        })
        .catch(e => {
          try {
            let message = 'There was an error converting the applicant to an operative. Please review for missing fields'
            if (e.response.data.operativedetails[0].sage_id !== undefined) {
              message = e.response.data.operativedetails[0].sage_id
            }
            generalMethods.failedMessage(message)
          } catch {
            generalMethods.failedMessage('There was an error saving the Applicant. Please review for missing fields')
          }
        })
    },

    recallApplicant(vuexContext, context) {
      const data = JSON.stringify(vuexContext.state.currentApplicationDetails)
      jwt.axiosIns.put(`/recallapplicant/${context.operativeid}/`, data)
        .then(response => {
          if (response.data.message !== undefined) {
            generalMethods.failedMessage(response.data.message)
          } else {
            generalMethods.successMessage('Success!', `Applicant '${vuexContext.state.currentApplicationDetails.firstname} ${vuexContext.state.currentApplicationDetails.lastname}' has been recalled.`)
            router.go(-1) // Return to previous page
          }
        })
        .catch(e => {
          try {
            let message = 'There was an error converting the applicant to an operative. Please review for missing fields'
            if (e.response.data.operativedetails[0].sage_id !== undefined) {
              message = e.response.data.operativedetails[0].sage_id
            }
            generalMethods.failedMessage(message)
          } catch {
            generalMethods.failedMessage('There was an error saving the Applicant. Please review for missing fields')
          }
        })
    },

    acceptApplicant(vuexContext, context) {
      if (vuexContext.state.currentApplicationDetails.bankdetails === undefined || vuexContext.state.currentApplicationDetails.bankdetails.length === 0) {
        vuexContext.commit('addBankDetails')
      }

      if (vuexContext.state.currentApplicationDetails.gdprdetails === undefined || vuexContext.state.currentApplicationDetails.gdprdetails.length === 0) {
        vuexContext.commit('addGDPRDetails')
      }

      if (vuexContext.state.currentApplicationDetails.entitysync === undefined || vuexContext.state.currentApplicationDetails.entitysync.length === 0) {
        vuexContext.commit('addSyncDetails')
      }

      if (vuexContext.state.currentApplicationDetails.entityclients === undefined || vuexContext.state.currentApplicationDetails.entityclients.length === 0) {
        vuexContext.commit('addClientDetails')
      }

      const data = JSON.stringify(vuexContext.state.currentApplicationDetails)
      /* eslint-disable */
      jwt.axiosIns.put(`/acceptapplicant/${context.operativeid}/`, data )
      /* eslint-enable */
        .then(response => {
          if (response.data.message !== undefined) {
            generalMethods.failedMessage(response.data.message)
          } else {
            generalMethods.successMessage('Success!', `Applicant '${vuexContext.state.currentApplicationDetails.firstname} ${vuexContext.state.currentApplicationDetails.lastname}' has been accepted as an operative.`)
            router.go(-1) // Return to previous page
          }
        })
        .catch(e => {
          try {
            let message = 'There was an error converting the applicant to an operative. Please review for missing fields'
            if (e.response.data.operativedetails[0].sage_id !== undefined) {
              message = e.response.data.operativedetails[0].sage_id
            }
            generalMethods.failedMessage(message)
          } catch {
            generalMethods.failedMessage('There was an error saving the Applicant. Please review for missing fields')
          }
        })
    },
  },
}
